/**
 * @typedef {Object} ContentTypeFilter
 * @property {string} key
 * @property {string} searchParamName
 * @property {Function} queryBuilder
 */

/**
 * @typedef {Object} ContentType
 * @property {string} key
 * @property {string} component
 * @property {string} [fields]
 * @property {number} [limit]
 * @property {ContentTypeFilter} [filter]
 * @property {Object<string, number>} [limitFilteredBy]
 */

/**
 * @enum {ContentType}
 * @type {Object.<string, ContentType>}
 */
export const CONTENT_TYPE = {
  PAGE: {
    key: 'pages',
    component: 'page',
  },
};
