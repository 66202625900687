import classnames from 'classnames';
import React from 'react';

export const Title = ({ children, color, align = 'left', size = 'medium' }) => {
  return (
    <div
      className={classnames(
        'section-title',
        `section-title--color-${color}`,
        `section-title--align-${align}`,
      )}
    >
      <div
        className={classnames(
          'section-title-title',
          `section-title--size-${size}`,
        )}
      >
        {children}
      </div>
    </div>
  );
};
