import { storyblokEditable } from '@storyblok/react';
import classnames from 'classnames';
import React from 'react';

import { Image } from '../../components/image';

import { isImage } from '../../helpers/image';

export const Group = ({ blok, index, children }) => {
  return (
    <div
      {...storyblokEditable(blok)}
      className={classnames('group', {
        'group--first': index === 0,
        'group--without-gradient': blok.without_gradient,
      })}
    >
      {isImage(blok.background) ? (
        <div className="group__background">
          <Image
            image={blok.background}
            sizes="(min-width: 769px) 100vw, 300vw"
          />
        </div>
      ) : null}

      <div className="group__gradient" />

      <div className="group__body">{children}</div>
    </div>
  );
};
