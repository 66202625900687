import React from 'react';

export const ArrowTopRightCirclePrimary = () => {
  return (
    <img
      src="/icons/arrow-top-right-circle-primary.svg"
      width="128"
      height="128"
      alt=""
      draggable="false"
    />
  );
};

export const StarPrimary = () => {
  return (
    <img
      className="level-up__blocks__item__icon"
      src="/icons/star-primary.svg"
      width="181"
      height="186"
      alt=""
      draggable="false"
    />
  );
};

export const SendIcon = () => {
  return (
    <img
      src="/icons/send.svg"
      width="75"
      height="56"
      alt=""
      draggable="false"
    />
  );
};

export const Check = () => {
  return (
    <img
      src="/icons/check.svg"
      width="15"
      height="10"
      alt=""
      draggable="false"
    />
  );
};

export const YellowCheck = () => {
  return (
    <img
      src="/icons/yellow-check.svg"
      width="30"
      height="30"
      alt=""
      draggable="false"
    />
  );
};

export const BlackCheck = () => {
  return (
    <img
      src="/icons/black-check.svg"
      width="30"
      height="30"
      alt=""
      draggable="false"
    />
  );
};

export const GreyCross = () => {
  return (
    <img
      src="/icons/grey-cross.svg"
      width="30"
      height="30"
      alt=""
      draggable="false"
    />
  );
};
