import { storyblokEditable } from '@storyblok/react';
import classnames from 'classnames';
import React from 'react';

import { GreyCross, YellowCheck } from '../../components/icons';
import { Image } from '../../components/image';
import { MaxWidth } from '../../components/max-width';

import { isImage } from '../../helpers/image';
import { render } from '../../helpers/storyblok/rich-text';
import { markdownToHtml } from '../../helpers/string';

export const ComparativeTable = ({ blok }) => {
  const columnsCount = blok.columns.length;

  return (
    <div
      {...storyblokEditable(blok)}
      className={classnames('comparative-table', {
        'comparative-table--without-border': blok.without_border,
        'comparative-table--without-gradient': blok.without_gradient,
      })}
      style={{
        '--columns': columnsCount,
      }}
    >
      <div className="comparative-table__desktop-gradient" />

      <MaxWidth>
        <div className="comparative-table__content">
          <h2
            className="comparative-table__title"
            dangerouslySetInnerHTML={{
              __html: markdownToHtml(blok.title),
            }}
          />
          <div className="comparative-table__description">
            {render(blok.description)}
          </div>
        </div>

        <div className="comparative-table__gradient">
          <div className="comparative-table__scroll">
            <div className="comparative-table__padding">
              <div className="comparative-table__table">
                <div className="comparative-table__header">
                  {blok.columns.map((column, index) => {
                    return (
                      <div
                        style={{
                          '--index': index + 1,
                        }}
                        key={column._uid}
                        className={classnames(
                          'comparative-table__header__cell',
                          {
                            'comparative-table__header__cell--highlighted':
                              isImage(column.logo),
                          },
                        )}
                      >
                        {isImage(column.logo) ? (
                          <img
                            className="comparative-table__header__cell__logo"
                            src="/icons/star-primary.svg"
                            alt="SparkM logo"
                          />
                        ) : null}
                        <h3 className="comparative-table__header__cell__title">
                          {column.title}
                        </h3>
                        <Image image={column.logo} />
                      </div>
                    );
                  })}
                </div>

                <div className="comparative-table__rows">
                  {blok.rows.map((row, index) => {
                    return (
                      <div className="comparative-table__row" key={row._uid}>
                        <div className="comparative-table__row__label">
                          <span className="comparative-table__row__title">
                            {row.title}
                          </span>
                        </div>

                        {blok.columns.map((column, columnIndex) => {
                          const columnRow = column.rows[index];

                          const cellValue = columnRow.text ? (
                            columnRow.text
                          ) : columnRow.toggle ? (
                            <YellowCheck />
                          ) : (
                            <GreyCross />
                          );

                          return (
                            <div
                              style={{
                                '--index': columnIndex + 1,
                              }}
                              key={column._uid}
                              className={classnames(
                                'comparative-table__row__cell',
                                {
                                  'comparative-table__row__cell--highlighted':
                                    isImage(column.logo),
                                },
                              )}
                            >
                              <span>{cellValue}</span>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </MaxWidth>
    </div>
  );
};
