import classnames from 'classnames';
import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';

import { Link } from '../link';

const CLOSE_SCROLL_THRESHOLD = 32;

export const MobileMenu = ({ blok, page }) => {
  const lastScrollTop = useRef(0);
  const [open, setOpen] = useState(false);

  const toggle = useCallback(() => {
    lastScrollTop.current = typeof window !== 'undefined' ? window.scrollY : 0;

    setOpen((state) => {
      return !state;
    });
  }, [setOpen]);

  const handleScroll = useCallback(() => {
    if (open) {
      const scrollTop = typeof window !== 'undefined' ? window.scrollY : 0;

      if (
        Math.abs(lastScrollTop.current - scrollTop) > CLOSE_SCROLL_THRESHOLD
      ) {
        setOpen(false);
      }
    }
  }, [open, setOpen]);

  useLayoutEffect(() => {
    if (typeof document !== 'undefined' && open) {
      document.addEventListener('scroll', handleScroll, { passive: true });
    }

    return () => {
      if (typeof document !== 'undefined') {
        document.removeEventListener('scroll', handleScroll);
      }
    };
  }, [open]);

  return (
    <>
      {open ? (
        <button
          className="mobile-menu-overlay"
          onClick={toggle}
          aria-label="Close"
        />
      ) : null}

      <header
        className={classnames('mobile-menu', {
          'mobile-menu--open': open,
        })}
      >
        <button className="mobile-menu__button" onClick={toggle}>
          <span className="mobile-menu__button__text">Menu</span>

          <img
            src="/icons/cross-white.svg"
            width="14"
            height="14"
            alt=""
            className="mobile-menu__button__cross"
          />
        </button>

        <menu className="mobile-menu__links">
          <ul>
            {[...blok.content.links, ...(blok.content.cta || [])].map(
              (item) => {
                return (
                  <li key={item._uid}>
                    <Link
                      link={item.link}
                      className={classnames('mobile-menu__link', {
                        'mobile-menu__link--current':
                          item.link.cached_url === page.full_slug,
                      })}
                    >
                      {item.title}
                    </Link>
                  </li>
                );
              },
            )}
          </ul>
        </menu>
      </header>
    </>
  );
};
