import { storyblokEditable } from '@storyblok/react';
import classnames from 'classnames';
import React from 'react';

import { Button } from '../../components/button';
import { ArrowTopRightCirclePrimary } from '../../components/icons';
import { MaxWidth } from '../../components/max-width';

import { render } from '../../helpers/storyblok/rich-text';
import { markdownToHtml } from '../../helpers/string';

export const Hero = ({ blok, index }) => {
  return (
    <div
      {...storyblokEditable(blok)}
      className={classnames('hero', {
        'hero--first': index === 0,
        'hero--large-content': blok.large_content,
        'hero--without-gradient': blok.without_gradient,
      })}
    >
      <div className="hero__gradient" />

      <MaxWidth>
        <div className="hero__content">
          <h1
            className="hero__title"
            dangerouslySetInnerHTML={{
              __html: markdownToHtml(blok.title),
            }}
          />

          <div className="hero__description">{render(blok.description)}</div>

          {blok.cta?.length > 0 ? (
            <div className="hero__description__cta">
              {(blok.cta || []).map((item) => {
                return (
                  <Button
                    key={item._uid}
                    link={item.link}
                    theme="white"
                    dangerouslySetInnerHTML={{
                      __html: markdownToHtml(item.title),
                    }}
                    rightIcon={<ArrowTopRightCirclePrimary />}
                  />
                );
              })}
            </div>
          ) : null}
        </div>
      </MaxWidth>
    </div>
  );
};
