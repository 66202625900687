import { storyblokEditable } from '@storyblok/react';
import classnames from 'classnames';
import React from 'react';

import { MaxWidth } from '../../components/max-width';

import { render } from '../../helpers/storyblok/rich-text';

export const Text = ({ blok, index }) => {
  return (
    <div
      {...storyblokEditable(blok)}
      className={classnames('text', {
        'text--first': index === 0,
      })}
    >
      <MaxWidth>
        <div className="text__wrap">{render(blok.content)}</div>
      </MaxWidth>
    </div>
  );
};
