import { storyblokEditable } from '@storyblok/js';
import classNames from 'classnames';
import React from 'react';

import { MaxWidth } from '../../components/max-width';

import { generateImageProps } from '../../helpers/image';
import { render } from '../../helpers/storyblok/rich-text';
import { markdownToHtml } from '../../helpers/string';

export const Founder = ({ blok, index }) => {
  const El = blok.headingLevel || 'h1';

  return (
    <div
      {...storyblokEditable(blok)}
      className={classNames('founder', {
        'founder--is-fist': index === 0,
      })}
    >
      <MaxWidth>
        <div className="founder__top">
          <El
            className="founder__top__title"
            dangerouslySetInnerHTML={{
              __html: markdownToHtml(blok.title),
            }}
          />
          <div className="founder__top__content">{render(blok.content1)}</div>
        </div>

        <div className="founder__image">
          <img
            {...generateImageProps(blok.image, {
              sizes: '(min-width: 769px) 600px, 50vw',
              draggable: 'false',
            })}
          />
        </div>
        <div className="founder__content">
          <div className="founder__content-blok">
            <img
              className="founder__content-blok__quotes-first"
              src="/icons/quotes.svg"
              alt=""
            />
            {render(blok.content2)}
            <img
              className="founder__content-blok__quotes-last"
              src="/icons/quotes.svg"
              alt=""
            />
          </div>
        </div>
      </MaxWidth>
    </div>
  );
};
