import { PageLayout } from './page.js';

const LAYOUT = {
  page: PageLayout,
};

const DEFAULT_LAYOUT = LAYOUT.page;

export const getLayout = (name) => {
  return LAYOUT[name] || DEFAULT_LAYOUT;
};
