import { storyblokEditable } from '@storyblok/react';
import classnames from 'classnames';
import React from 'react';

import { Image } from '../../components/image';
import { MaxWidth } from '../../components/max-width';
import { Title } from '../../components/title';

import { render } from '../../helpers/storyblok/rich-text';

import { Form } from './form';

export const HeroForm = ({ blok, index }) => {
  const form = blok?.form[0];

  const sparkle = blok.sparkle;
  const content = blok.content.content[0].content;

  return (
    <div
      {...storyblokEditable(blok)}
      className={classnames('hero-form', {
        'hero-form--first': index === 0,
      })}
    >
      <MaxWidth>
        <div className="hero-form__wrap">
          <div className="hero-form__content">
            {sparkle ? (
              <img className="hero-form__sparkle" src="/icons/sparkle.svg" />
            ) : null}
            {blok.title ? (
              <Title color="white">{render(blok.title)}</Title>
            ) : null}
            {content ? (
              <div className="hero-form__text">{render(blok.content)}</div>
            ) : null}
            {blok.subtext ? (
              <div className="hero-form__subtext">{render(blok.subtext)}</div>
            ) : null}
            {form ? <Form blok={blok} /> : null}
          </div>
          <div className="hero-form__image">
            <Image image={blok.image} />
          </div>
        </div>
      </MaxWidth>
    </div>
  );
};
