import React from 'react';

import { Group } from '../slices/group';
import { getSlice } from '../slices/index.js';

import { Layout } from '../components/layout';

import { useData } from '../helpers/data.js';

export const PageLayout = () => {
  const { story } = useData();
  const data = useData();

  const body = story.content?.body || [];

  return (
    <Layout>
      {body.map((item, index) => {
        if (item.component === 'group') {
          return (
            <Group key={item._uid} index={index} blok={item}>
              {item.body.map((item, index) => {
                const Slice = getSlice(item.component);

                return Slice ? (
                  <Slice key={item._uid} index={index} blok={item} />
                ) : null;
              })}
            </Group>
          );
        }

        const Slice = getSlice(item.component);

        return Slice ? (
          <Slice key={item._uid} index={index} blok={item} />
        ) : null;
      })}
    </Layout>
  );
};
