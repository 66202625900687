import { storyblokEditable } from '@storyblok/react';
import classnames from 'classnames';
import React from 'react';

import { MaxWidth } from '../../components/max-width';
import { Title } from '../../components/title';

import { markdownToHtml } from '../../helpers/string';

export const TitleSection = ({ blok, index }) => {
  const El = blok.headingLevel || 'h1';
  return (
    <div
      {...storyblokEditable(blok)}
      className={classnames('title-section', {
        'title-section--first': index === 0,
      })}
    >
      <MaxWidth>
        <Title color="white" size="large">
          <El
            className="title-section__content"
            dangerouslySetInnerHTML={{
              __html: markdownToHtml(blok.content),
            }}
          />
        </Title>
      </MaxWidth>
    </div>
  );
};
