import { storyblokEditable } from '@storyblok/react';
import classnames from 'classnames';
import React from 'react';

import { Button } from '../../components/button';
import {
  ArrowTopRightCirclePrimary,
  StarPrimary,
} from '../../components/icons';
import { Image } from '../../components/image';
import { MaxWidth } from '../../components/max-width';

import { isImage } from '../../helpers/image';
import { render } from '../../helpers/storyblok/rich-text';
import { markdownToHtml } from '../../helpers/string';

export const LevelUp = ({ blok }) => {
  return (
    <div
      {...storyblokEditable(blok)}
      className={classnames('level-up', {
        'level-up--without-border': blok.without_border,
        'level-up--without-gradient': blok.without_gradient,
      })}
    >
      <div className="level-up__gradient" />

      <MaxWidth>
        <h2
          className="level-up__title"
          dangerouslySetInnerHTML={{
            __html: markdownToHtml(blok.title),
          }}
        />

        {blok.blocks?.length > 0 ? (
          <ul className="level-up__blocks">
            {(blok.blocks || []).map((item) => {
              return (
                <li
                  className={classnames('level-up__blocks__item', {
                    'level-up__blocks__item--title': item.title,
                  })}
                  key={item._uid}
                >
                  {isImage(item.image) ? (
                    <div className="level-up__blocks__item__icon">
                      <Image image={item.image} />
                    </div>
                  ) : (
                    <StarPrimary />
                  )}

                  <h3
                    className="level-up__blocks__item__title"
                    dangerouslySetInnerHTML={{
                      __html: markdownToHtml(item.title),
                    }}
                  />
                  <div className="level-up__blocks__item__content">
                    {render(item.content)}
                  </div>
                </li>
              );
            })}
          </ul>
        ) : null}

        {blok.cta?.length > 0 ? (
          <div className="level-up__cta">
            {(blok.cta || []).map((item) => {
              return (
                <Button
                  key={item._uid}
                  link={item.link}
                  theme="white"
                  dangerouslySetInnerHTML={{
                    __html: markdownToHtml(item.title),
                  }}
                  rightIcon={<ArrowTopRightCirclePrimary />}
                />
              );
            })}
          </div>
        ) : null}
      </MaxWidth>
    </div>
  );
};
