import { storyblokEditable } from '@storyblok/react';
import classnames from 'classnames';
import React from 'react';

import { Image } from '../../components/image';
import { MaxWidth } from '../../components/max-width';

import { render } from '../../helpers/storyblok/rich-text';

export const Reasons = ({ blok }) => {
  return (
    <div
      {...storyblokEditable(blok)}
      className={classnames('reasons', {
        'reasons--without-border': blok.without_border,
      })}
    >
      <MaxWidth>
        <h2 className="reasons__title">
          <span>{blok.number} </span>
          <span>{blok.title}</span>
        </h2>

        <div className="reasons__content">
          <ul className="reasons__list">
            {(blok.reasons || []).map((reason, index) => {
              return (
                <li key={reason._uid} className="reasons__list__item">
                  <span className="reasons__list__item__number">
                    {index + 1}
                  </span>

                  <span className="reasons__list__item__content">
                    {render(reason.content)}
                  </span>
                </li>
              );
            })}
          </ul>

          <div className="reasons__image">
            <Image image={blok.image} />
          </div>
        </div>
      </MaxWidth>
    </div>
  );
};
