import classnames from 'classnames';
import React from 'react';

import { CONFIG } from '../../config';

import { Button } from '../button';
import { Link } from '../link';
import { MaxWidth } from '../max-width';

export const Header = ({ blok, page }) => {
  return (
    <header className="header">
      <MaxWidth>
        <Link href="/" className="header__logo">
          <img
            src="/logo-white.svg"
            width="603"
            height="130"
            alt={CONFIG.title}
          />
        </Link>

        <div className="header__links">
          {blok.content.links.map((item) => {
            return (
              <Link
                key={item._uid}
                link={item.link}
                className={classnames('header__link', {
                  'header__link--current':
                    item.link.cached_url === page.full_slug,
                })}
              >
                {item.title}
              </Link>
            );
          })}

          {blok.content.cta?.length > 0 ? (
            <div className="header__cta">
              {(blok.content.cta || []).map((item) => {
                return (
                  <Button
                    key={item._uid}
                    link={item.link}
                    height="small"
                    impacting
                  >
                    {item.title}
                  </Button>
                );
              })}
            </div>
          ) : null}
        </div>

        <hr className="header__hr" />
      </MaxWidth>
    </header>
  );
};
