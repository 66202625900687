import React from 'react';

import { CONFIG } from '../../config';

import { Link } from '../link';
import { MaxWidth } from '../max-width';

export const Footer = ({ blok }) => {
  if (!blok) {
    return null;
  }

  return (
    <footer className="footer">
      <MaxWidth>
        <hr className="footer__hr" />

        <div className="footer__content">
          <div className="footer__content__column">
            <Link href="/" className="footer__content__column__logo">
              <img
                src="/logo-white.svg"
                width="603"
                height="130"
                alt={CONFIG.title}
              />
            </Link>
          </div>

          {(blok.content.columns || []).map((item) => {
            return (
              <div className="footer__content__column" key={item._uid}>
                <h2
                  dangerouslySetInnerHTML={{
                    __html: item.title,
                  }}
                />

                <ul className="footer__content__column__links">
                  {(item.links || []).map((link) => {
                    return (
                      <li key={link._uid}>
                        <Link link={link.link}>{link.title}</Link>
                      </li>
                    );
                  })}
                </ul>

                {item.legalNotice ? (
                  <p className="footer__content__column__sub-text">
                    {item.legalNotice.replace(
                      '{YEAR}',
                      new Date().getFullYear(),
                    )}
                  </p>
                ) : null}
              </div>
            );
          })}
        </div>
      </MaxWidth>
    </footer>
  );
};
