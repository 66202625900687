import classnames from 'classnames';
import React from 'react';
import Spinner from 'react-svg-spinner';

import { isLink } from '../../helpers/storyblok/is-link';

import { Link } from '../link';

delete Spinner.defaultProps;

export const Button = ({
  as = 'button',
  link = null,
  impacting = false,
  theme = 'primary',
  height = 'normal',
  rightIcon = null,
  loading = false,
  children,
  dangerouslySetInnerHTML,
  disabled = false,
  ...rest
}) => {
  const El = isLink(link) ? Link : as;

  return (
    <El
      className={classnames(
        'button',
        `button--theme-${theme}`,
        `button--height-${height}`,
        {
          'button--has-right-icon': rightIcon,
          'button--impacting': impacting,
          'button--disabled': disabled,
          'button--loading': loading,
        },
      )}
      link={El === Link ? link : null}
      disabled={El === 'button' ? disabled : undefined}
      {...rest}
    >
      <span className="button__background"></span>

      <span className="button__content">
        <span
          className="button__text"
          {...(children ? { children } : { dangerouslySetInnerHTML })}
        />

        {rightIcon && !loading ? (
          <span className="button__icon">{rightIcon}</span>
        ) : null}

        {rightIcon && loading ? (
          <div className="button__icon">
            <Spinner
              color="black"
              speed="fast"
              size="16"
              gap={4}
              thickness={4}
            />
          </div>
        ) : null}
      </span>
    </El>
  );
};
