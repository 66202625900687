import { Companies } from './companies';
import { ComparativeTable } from './comparative-table';
import { Founder } from './founder';
import { Hero } from './hero';
import { HeroForm } from './hero-form';
import { LevelUp } from './level-up';
import { Reasons } from './reasons';
import { Text } from './text';
import { TitleSection } from './title-section';

const SLICE = {
  hero: Hero,
  levelUp: LevelUp,
  reasons: Reasons,
  text: Text,
  heroForm: HeroForm,
  founder: Founder,
  companies: Companies,
  'title-section': TitleSection,
  comparativeTable: ComparativeTable,
};

export const getSlice = (name) => {
  return SLICE[name] || null;
};
