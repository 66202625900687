import { storyblokEditable } from '@storyblok/js';
import classNames from 'classnames';
import React from 'react';

import { MaxWidth } from '../../components/max-width';

import { generateImageProps } from '../../helpers/image';
import { markdownToHtml } from '../../helpers/string';

export const Companies = ({ blok, index }) => {
  return (
    <div
      {...storyblokEditable(blok)}
      className={classNames('companies', {
        'companies--is-fist': index === 0,
        'companies--small': blok.small,
      })}
    >
      <MaxWidth>
        {blok.title ? (
          <h2
            className="companies__title"
            dangerouslySetInnerHTML={{
              __html: markdownToHtml(blok.title),
            }}
          />
        ) : null}

        <div className="companies__logos">
          {blok.logos.map((logo) => {
            return (
              <div key={logo.id} className="companies__logo">
                <img
                  {...generateImageProps(logo, {
                    sizes: '250px',
                    draggable: 'false',
                  })}
                />
              </div>
            );
          })}
        </div>
      </MaxWidth>
    </div>
  );
};
